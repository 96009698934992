import { config } from '../../constants/config'
import { restApi } from '../../interceptor'
import qs from 'qs' //querystring parsing and stringifying library
import { getHash } from '../../utils/utils'

export function login(username, password) {
    return (dispatch) => {
        dispatch({ type: 'LOGIN_PENDING' })
        restApi({
            method: 'POST',
            url: `${process.env.REACT_APP_AUTH_URL}/connect/token`,
            // headers: authHeader(),
            data: qs.stringify({
                client_id: process.env.REACT_APP_CLIENT_ID,
                grant_type: config.grantTypePassword,
                scope: config.scope,
                username: username,
                password: password
            })
        })
            .then((response) => {
                dispatch({ type: 'LOGIN_FULFILLED', payload: response })

                var hashString = getHash(username)
                var localStorageItem = localStorage.getItem(hashString)

                if (localStorageItem === null) {
                    localStorage.setItem(hashString, JSON.stringify([]))
                }

                localStorage.setItem('logged_in_user', JSON.stringify(username))
            })
            .catch((error) => {
                dispatch({ type: 'LOGIN_REJECTED', payload: error })
            })
    }
}

export function logout() {
    return (dispatch) => {
        dispatch({ type: 'LOGOUT' })
    }
}

export function logoutForced() {
    return (dispatch) => {
        dispatch({ type: 'LOGOUT_FORCED' })
    }
}

export function goingToLogout() {
    return (dispatch) => {
        dispatch({ type: 'GOING_TO_LOGOUT' })
    }
}
