export const en = {
    username: 'Username',
    password: 'Password',

    //Error Messages
    invalidUsernameOrPassword: 'Invalid Username Or Password'
}

export const de = {
    username: 'Username',
    password: 'Password',

    //Error Messages
    invalidUsernameOrPassword: 'Invalid Username Or Password'
}