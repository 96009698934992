import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../constants/routeStrings'

export class LandingPage extends Component {
    render() {
        return (
            <div className="container background">
                <div className="row">
                    <div className="col-12">
                        <h1 className="mb-4">QCM</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Link to={routes.calculation}>
                            <div className="card-qcm mb-4">
                                <div className="p-4 card-qcm-header launch-icon-qcm"></div>
                                <div className="card-qcm-bottom p-3">
                                    <h5>Calculator</h5>
                                    <p>
                                        Convert units using different conversion
                                        standards
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-6">
                        <Link to={routes.settings}>
                            <div className="card-qcm">
                                <div className="p-4 card-qcm-header launch-icon-settings"></div>
                                <div className="card-qcm-bottom p-3">
                                    <h5>Settings</h5>
                                    <p>All your settings for the QCM</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
export default LandingPage
