
export const config = {

    // apiUrl: 'https://scu.implico.com/qcm/api',
    // authUrl: 'https://quality-ico-scu-auth.implico.com',

    // // Client Id
    // clientId: 'qcm-api',

    // // Client Secret
    // clientSecret: '407351ee-dc23-1e57-debd-a7b47415a65d',

    // Grant Type Password
    grantTypePassword: 'password',
    grantTypeRefreshToken: 'refresh_token',

    // Scope
    scope: 'qcm-scope offline_access ',
}