import axios from 'axios'
import store from './store'
import { config as constants } from './constants/config'
import qs from 'qs' //querystring parsing and stringifying library
import { getExpireDate } from './utils/utils'
import { logoutForced } from './actions/authentication/loginActions'

const restApi = axios.create({
    // baseURL: config.apiUrl
})

const restApi2 = axios.create({
    // baseURL: config.apiUrl
})

// Intercept request before it is sent to check if the refresh token has expired
restApi.interceptors.request.use(
    (config) => {
        var user = JSON.parse(localStorage.getItem('user'))

        if (!user) {
            return config
        }

        var date = new Date(Date.now() - 10000)

        // Check if refresh token has expired
        if (new Date(user.expires_at).getTime() < date.getTime()) {
            restApi2({
                method: 'POST',
                url: `${process.env.REACT_APP_AUTH_URL}/connect/token`,
                data: qs.stringify({
                    client_id: constants.clientId,
                    client_secret: constants.clientSecret,
                    grant_type: constants.grantTypeRefreshToken,
                    refresh_token: user.refresh_token
                })
            })
                .then((response) => {
                    // Replace with the new refresh token
                    const retreivedRefreshUser = response.data
                    retreivedRefreshUser.expires_at = getExpireDate(
                        retreivedRefreshUser.expires_in
                    )
                    localStorage.setItem(
                        'user',
                        JSON.stringify(retreivedRefreshUser)
                    )
                })
                .catch((error) => {
                    // failed to refresh token, clear tokens and user info and send to login
                    store.dispatch(logoutForced())
                })
        }

        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

export { restApi }
