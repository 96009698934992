import React, { Component, Fragment } from 'react'
import { strings } from '../../constants/strings/mainStrings'
import { handleUTCDate, smoothScrollTop } from '../../utils/utils'
import conversions from './conversions.json'

//Css File
import './Output.css'

class Output extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: []
        }
    }

    handleGetFieldByUOM = (quantities, uom) => {
        var result = quantities.find((obj) => {
            return obj.uom === uom && obj.mode === 'OUT'
        })

        return result !== undefined && result !== null ? result.value : null
    }

    handleBackButton = () => {
        this.props.handleBack()
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.conversionStandard !== prevProps.conversionStandard ||
            this.props.material !== prevProps.material ||
            this.props.layout !== prevProps.layout
        ) {
            if (
                prevProps.conversionStandard !== null &&
                prevProps.material !== null &&
                prevProps.layout !== null
            ) {
                this.props.handleBack()
            }

            var fields = []

            var conversion = conversions.find(
                (element) =>
                    element.conversion === this.props.conversionStandard
            )

            var material = conversion.materials.find(
                (element) => element.material === this.props.material
            )

            var layout = material.layouts.find(
                (element) => element.layout === this.props.layout
            )

            fields = layout.output_fields

            this.setState({ fields: fields })
        }
    }

    handleNewCalculation = () => {
        this.props.handleBack()
        this.props.handleNewCalculation()

        setTimeout(() => {
            smoothScrollTop()
        }, 100)
    }

    render() {
        const { calculationResult, conversion, quantities } = this.props
        const { fields } = this.state

        return (
            <div
                className="default-output-layout-container mt-4 mt-sm-0"
                id="output-section"
            >
                <h2>
                    <span className="icon-il--calculator"></span> Output
                </h2>

                <div className="default-output-layout">
                    <strong>{strings.timeWhenCalculated}:</strong>{' '}
                    <span> {handleUTCDate(conversion.conversionUtc)}</span>
                    <hr />
                    <strong>{strings.yourReference}:</strong>{' '}
                    <span> {conversion.clientRef}</span>
                    <hr />
                    <strong>{strings.appliedProcedure}:</strong>{' '}
                    <span> {calculationResult.appliedProcedure}</span>
                    <br />
                    {fields !== [] &&
                        fields.map((field) => (
                            <Fragment key={field.code}>
                                <span key={field.code}>
                                    <b>{field.description}:</b> <br />{' '}
                                    {this.handleGetFieldByUOM(
                                        quantities,
                                        field.code
                                    )}
                                </span>
                                <br />
                            </Fragment>
                        ))}
                    <br />
                    {/* <button className="btn btn-flow-outline mb-3">{strings.saveCalculation}</button> */}
                    <button
                        onClick={this.handleNewCalculation}
                        className="btn btn-outline-primary mt-3"
                    >
                        {strings.newCalculation}
                    </button>
                    {/* <button className="btn btn-flow-outline mb-3 ">{strings.printCalculation}</button>
                    <button className="btn btn-flow-outline">{strings.downloadCalculation}</button> */}
                </div>
            </div>
        )
    }
}

export default Output
