import { getExpireDate } from '../utils/utils'

export default function reducer(
    state = {
        user: JSON.parse(localStorage.getItem('user')),
        fetching: false,
        loginError: {},
        showError: false,
        goingToLogout: false
    },
    action
) {
    switch (action.type) {
        case 'LOGIN_PENDING':
            return {
                ...state,
                fetching: true,
                loginError: {},
                showError: false,
                goingToLogout: false
            }

        case 'LOGIN_FULFILLED':
            const retrievedUser = action.payload.data

            retrievedUser.expires_at = getExpireDate(retrievedUser.expires_in)
            localStorage.setItem('user', JSON.stringify(retrievedUser))

            return {
                ...state,
                fetching: false,
                user: retrievedUser,
                loginError: {},
                showError: false
            }

        case 'LOGIN_REJECTED':
            return {
                ...state,
                fetching: false,
                loginError: action.payload.response.data,
                showError: true
            }

        case 'LOGOUT':
            localStorage.removeItem('user')
            return { ...state, user: null, authenticatedUserHash: null }

        case 'LOGOUT_FORCED':
            localStorage.removeItem('user')
            return { ...state, user: null, authenticatedUserHash: null }

        case 'GOING_TO_LOGOUT':
            return { ...state, goingToLogout: true }

        default:
            break
    }
    return state
}
