import { strings } from '../constants/strings/mainStrings'

var crypto = require('crypto')

// Calculates the refresh token's refresh date
export const getExpireDate = (expires_in) => {
    return new Date(Date.now() + expires_in * 1000)
}

// Return date in user's locale time
export const handleUTCDate = (utcDate) => {
    var date = new Date(Date.parse(utcDate))

    return utcDate !== null && utcDate !== '' && utcDate !== undefined
        ? date.toLocaleString()
        : ''
}

// Build Calculation Errors Object
export const buildErrorsObject = (errors) => {
    var errorsObject = {}
    // errorsObject.usBarrels = errors['[0].conversions[0].quantities[0].value.0'] ?? []
    // errorsObject.usGallons = errors['[0].conversions[0].quantities[1].value.1'] ?? []
    // errorsObject.productTemperature = errors['[0].conversions[0].quantities[2].value.2'] ?? []
    // errorsObject.api = errors['[0].conversions[0].quantities[3].value.3'] ?? []
    // errorsObject.apiTemperature = errors['[0].conversions[0].quantities[4].value.4'] ?? []

    return errorsObject
}

// Replace ',' with '.' to see if it's a valid number
export const isNumber = (n) => {
    n = n.toString().replace(',', '.')

    return !isNaN(Number(n)) && isFinite(Number(n))
}

// Replace ',' with '.' and return number
export const getNumberWithoutCommas = (n) => {
    n = n.toString().replace(',', '.')

    return Number(n)
}

// Return string's hash
export const getHash = (string) => {
    return crypto.createHash('sha256').update(string).digest('hex')
}

export const getUserSettings = () => {
    return JSON.parse(
        localStorage.getItem(
            getHash(
                localStorage
                    .getItem('logged_in_user')
                    .replace('"', '')
                    .replace('"', '')
            )
        )
    )
}

export const getUsernameHash = () => {
    return getHash(
        localStorage.getItem('logged_in_user').replace('"', '').replace('"', '')
    )
}

export const getAccessToken = () => {
    var userLocalStorage = JSON.parse(localStorage.getItem('user'))

    try {
        return userLocalStorage.access_token
    } catch (error) {
        return null
    }
}

export const getHeader = () => {
    return {
        Authorization: 'Bearer ' + getAccessToken()
    }
}

export const generateCalculationObjects = (
    inputFields,
    outputFields,
    conversionStandard,
    material,
    layout
) => {
    var array = []

    outputFields.forEach((element) => {
        let obj = {
            mode: 'OUT',
            uom: element.code,
            roundTo: tryToGetSettingsValue(
                conversionStandard,
                material,
                layout,
                element.code
            )
        }

        array.push(obj)
    })

    Object.keys(inputFields).forEach((key) => {
        var value = inputFields[key]
        if (value !== undefined && value !== '' && value !== null) {
            var mode = ''
            var uom = ''

            if (key.includes('PRE')) {
                mode = 'PRE'
                uom = key.replace('PRE-', '')
            } else if (key.includes('POST')) {
                mode = 'POST'
                uom = key.replace('POST-', '')
            }

            let obj = {
                mode: mode,
                uom: uom,
                value: getNumberWithoutCommas(inputFields[key])
            }
            array.unshift(obj)
        }
    })

    return array
}

export const tryToGetSettingsValue = (
    conversionStandard,
    material,
    layout,
    code
) => {
    var settings = getUserSettings()
    var foundConversion = settings.find(
        (element) => element.conversion === conversionStandard
    )

    if (foundConversion === undefined || foundConversion === null) {
        return null
    }

    var foundMaterial = foundConversion.materials.find(
        (element) => element.material === material
    )

    if (foundMaterial === undefined || foundMaterial === null) {
        return null
    }

    var foundLayout = foundMaterial.layouts.find(
        (element) => element.layout === layout
    )

    if (foundLayout === undefined || foundLayout === null) {
        return null
    }
    var returnedValue = foundLayout.output_fields[code]

    if (isNaN(returnedValue) || returnedValue === '') {
        return null
    }

    return parseInt(returnedValue)
}

export const isInt = (n) => {
    return n % 1 === 0
}

export const returnInputValue = (value) => {
    var result = parseFloat(value).toFixed(2)
    return isInt(result) ? parseInt(result) : result
}

export const updateLocalStorageIfNeeded = (conversions) => {
    var settings = getUserSettings()

    conversions.forEach((conversion) => {
        var conversionName = conversion.conversion

        var materials = conversion.materials

        if (!conversionExists(settings, conversionName)) {
            // Create conversion
            createConversion(settings, conversionName)
            settings = getUserSettings()
        }

        materials.forEach((material) => {
            var materialName = material.material
            if (!materialExists(settings, conversionName, materialName)) {
                // Create material
                createMaterial(settings, conversionName, materialName)
                settings = getUserSettings()
            } else {
                var layouts = material.layouts
                layouts.forEach((layout) => {
                    var layoutName = layout.layout
                    if (
                        !layoutExists(
                            settings,
                            conversionName,
                            materialName,
                            layoutName
                        )
                    ) {
                        // Create layout
                        createLayout(
                            settings,
                            conversionName,
                            materialName,
                            layoutName,
                            layout.output_fields
                        )
                        settings = getUserSettings()
                    }
                })
            }
        })
    })
}

export const conversionExists = (settings, conversion) => {
    var foundConversion = settings.find(
        (element) => element.conversion === conversion
    )

    return foundConversion !== undefined
}

export const materialExists = (settings, conversion, material) => {
    var foundConversion = settings.find(
        (element) => element.conversion === conversion
    )
    var foundMaterial = foundConversion.materials.find(
        (element) => element.material === material
    )

    return foundMaterial !== undefined
}

export const layoutExists = (settings, conversion, material, layout) => {
    var foundConversion = settings.find(
        (element) => element.conversion === conversion
    )
    var foundMaterial = foundConversion.materials.find(
        (element) => element.material === material
    )
    var foundLayout = foundMaterial.layouts.find(
        (element) => element.layout === layout
    )

    return foundLayout !== undefined
}

export const createConversion = (settings, conversion) => {
    settings.push({
        conversion: conversion,
        materials: []
    })

    localStorage.setItem(getUsernameHash(), JSON.stringify(settings))
}

export const createMaterial = (settings, conversion, material) => {
    var foundConversionIndex = settings.findIndex(
        (element) => element.conversion === conversion
    )

    settings[foundConversionIndex].materials.push({
        material: material,
        layouts: []
    })

    localStorage.setItem(getUsernameHash(), JSON.stringify(settings))
}

export const createLayout = (
    settings,
    conversion,
    material,
    layout,
    outputFields
) => {
    var foundConversionIndex = settings.findIndex(
        (element) => element.conversion === conversion
    )
    var foundMaterialIndex = settings[foundConversionIndex].materials.findIndex(
        (element) => element.material === material
    )

    settings[foundConversionIndex].materials[foundMaterialIndex].layouts.push({
        layout: layout,
        output_fields: createOutputFieldsObject(outputFields)
    })

    localStorage.setItem(getUsernameHash(), JSON.stringify(settings))
}

export const createOutputFieldsObject = (outputFields) => {
    var object = {}

    outputFields.forEach((output) => {
        object[output.code] = output.defaultRoundTo.toString()
    })

    return object
}

export const handleErrorMessage = (errorObject) => {
    var errorMessage = ''

    try {
        if (errorObject.error === 'invalid_grant') {
            switch (errorObject.error_description) {
                case 'invalid_username_or_password':
                    errorMessage = strings.invalidUsernameOrPassword
                    break

                default:
                    break
            }
        }
    } catch (error) {}

    return errorMessage
}

export const smoothScrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}
