import { combineReducers } from 'redux'

//Import Reducers
import login from './loginReducer'
import mainCalculation from './calculationReducer'

export default combineReducers({
    login,
    mainCalculation
})
