import { buildErrorsObject } from '../utils/utils'

function getInitialFavourite() {
    const initial = localStorage.getItem('favourits')
    if (initial) return JSON.parse(initial)
    return []
}

function setFavouritsLocalStorage(data) {
    localStorage.setItem('favourits', JSON.stringify(data))
}

export default function reducer(
    state = {
        // --- Reducer Calculation Variables ---
        calculationResult: {},
        calculating: false,
        hasResult: false,
        showError: false,
        errorMessage: null,
        errors: {},
        favourits: getInitialFavourite(),
        inputObject: {}
    },
    action
) {
    switch (action.type) {
        case 'CALCULATE_RESULT_PENDING':
            return {
                ...state,
                calculating: true,
                hasResult: false,
                calculationResult: {},
                errors: {},
                showError: false,
                errorMessage: null
            }

        case 'CALCULATE_RESULT_FULFILLED':
            var result = action.payload.calculationResult
            var showError = false
            var errorMessage = null

            if (result.resultCode === -1) {
                showError = true
                errorMessage = result.resultMessage
            }

            return {
                ...state,
                calculating: false,
                hasResult: true,
                calculationResult: result,
                showError: showError,
                errorMessage: errorMessage
            }

        case 'CALCULATE_RESULT_REJECTED':
            return {
                ...state,
                calculating: false,
                hasResult: false,
                errors: buildErrorsObject(action.payload.errors),
                calculationResult: {}
            }

        case 'CLEAR_CALULATION_ERROR':
            return { ...state, showError: false, errorMessage: null }

        case 'RESET_CALCULATION_OUTPUT':
            return { ...state, hasResult: false, calculationResult: {} }

        case 'SAVE_SESSION_INPUT':
            return { ...state, inputObject: action.payload.inputObject }

        case 'CLEAR_SESSION_INPUT':
            return { ...state, inputObject: {} }
            
        case 'SET_FAVOURITS': {
            setFavouritsLocalStorage(action.payload)
            return { ...state, favourits: [...action.payload] }
        }

        default:
            break
    }

    return state
}
