import LocalizedStrings from 'react-localization'
import { en as loginStringEn, de as loginStringsDe } from './loginStrings'
import {
    en as calculationStringEn,
    de as calculationStringsDe
} from './calculationStrings'

export const strings = new LocalizedStrings({
    en: {
        ...loginStringEn,
        ...calculationStringEn
    },
    de: {
        ...loginStringsDe,
        ...calculationStringsDe
    }
})
