import React, { Component } from 'react'
import { connect } from 'react-redux'
import { strings } from '../../constants/strings/mainStrings'
import { handleErrorMessage } from '../../utils/utils'
import { login } from '../../actions/authentication/loginActions'
import { Icon } from 'react-icons-kit'
import { eye } from 'react-icons-kit/feather/eye'
import { eyeOff } from 'react-icons-kit/feather/eyeOff'

//Css File
import './Login.scss'

//import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const mapStateToProps = (state) => {
    return {
        user: state.login.user,
        fetching: state.login.fetching,
        loginError: state.login.loginError,
        showError: state.login.showError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => dispatch(login(username, password))
    }
}

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: ''
        }
    }

    handleChangeField = (name) => (event) => {
        this.setState({ [name]: event.target.value })
    }

    handleSubmitButton = () => {
        this.props.login(this.state.username, this.state.password)
    }

    state = {
        isPasswordShown: false
    }

    togglePasswordVisiblity = () => {
        const { isPasswordShown } = this.state
        this.setState({ isPasswordShown: !isPasswordShown })
    }
    render() {
        const { username, password } = this.state
        const { fetching, loginError, showError } = this.props
        const { isPasswordShown } = this.state

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3 background p-0">
                        <h1 className="m-4">Login</h1>

                        <div className="login-header d-flex justify-content-center">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/assets/img/login-header.png'
                                }
                                alt="QCM Logo"
                                className="qcm-logo-login"
                            />
                        </div>

                        <div className="login-content">
                            {/* Login Error Msg */}
                            {showError && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    <span className="icon-il--warning"></span>{' '}
                                    {handleErrorMessage(loginError)}
                                </div>
                            )}

                            <div className="default-input-layout">
                                <label>{strings.username} </label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username || ''}
                                    disabled={fetching}
                                    onChange={this.handleChangeField(
                                        'username'
                                    )}
                                />
                            </div>
                            <div className="default-input-layout">
                                <label>{strings.password} </label>
                                <div id="hide-show-pass">
                                    <Icon
                                        id="i"
                                        onClick={this.togglePasswordVisiblity}
                                        icon={isPasswordShown ? eye : eyeOff}
                                        size={30}
                                    />
                                    <input
                                        type={
                                            isPasswordShown
                                                ? 'text'
                                                : 'password'
                                        }
                                        id="password"
                                        value={password || ''}
                                        disabled={fetching}
                                        onChange={this.handleChangeField(
                                            'password'
                                        )}
                                    />
                                </div>
                            </div>

                            <button
                                disabled={
                                    username === '' ||
                                    password === '' ||
                                    fetching
                                }
                                onClick={this.handleSubmitButton}
                                className="btn btn-primary btn-success btn-calculate btn-flow-floating w-100 mt-4"
                            >
                                <span className="icon-il--log-in icon-l icon-l icon"></span>{' '}
                                Login
                            </button>

                            {fetching && (
                                <div className="login-one-moment-please">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/assets/img/hourglass.svg'
                                        }
                                        alt="logo"
                                        className="scu-hourglass rotate"
                                    />
                                    Logging in, please wait...
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
