import { applyMiddleware} from 'redux'
import { legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk'

import logger from 'redux-logger'
import promise from 'redux-promise-middleware'

import reducer from './reducers'

const store = createStore(
    reducer,
    +window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(promise, thunk, logger)
)

export default store
