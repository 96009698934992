import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

//Css File
import './Calculation.css'

//Calculation Actions
import {
    resetOutput,
    clearCalculationError
} from '../../actions/calculation/calculationActions'

//import Components
// import Input from './Input'
import InputV2 from './InputV2'
import Output from './Output'

import { smoothScrollTop } from '../../utils/utils'

const mapStateToProps = (state) => {
    return {
        goingToLogout: state.login.goingToLogout,
        calculationResult: state.mainCalculation.calculationResult,
        hasResult: state.mainCalculation.hasResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetOutput: () => dispatch(resetOutput()),
        clearCalculationError: () => dispatch(clearCalculationError())
    }
}

class Calculation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            conversionStandard: null,
            material: null,
            layout: null,
            change: false
        }
    }

    componentDidMount() {
        smoothScrollTop()
    }

    componentWillUnmount = () => {
        if (this.props.goingToLogout) {
            this.props.resetOutput()
            this.props.clearCalculationError()
        }
    }

    handleChanged = (fieldName, value) => {
        this.setState({ ...this.state, [fieldName]: value })
    }

    handleConversionAndMaterialAndLayoutChanged = (
        conversionStandard,
        material,
        layout
    ) => {
        this.setState({
            ...this.state,
            conversionStandard: conversionStandard,
            material: material,
            layout: layout
        })
    }

    handleNewCalculation = () => {
        this.setState({ change: !this.state.change })

        this.props.clearCalculationError()
    }

    handleBack = () => {
        this.props.resetOutput()
    }

    scrollToElement = (id) => {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        })
    }

    render() {
        const { calculationResult, hasResult } = this.props
        const { conversionStandard, material, layout, change } = this.state

        return (
            <div>
                <div className="container background">
                    {/*<h1 className="mb-3">Calculation</h1> */}

                    <div className="row">
                        <div className="col-sm-6">
                            {/* <Input /> */}
                            <InputV2
                                handleSubmitScroll={this.scrollToElement}
                                handleChanged={this.handleChanged}
                                handleConversionAndMaterialChanged={
                                    this
                                        .handleConversionAndMaterialAndLayoutChanged
                                }
                                change={change}
                                resetOutput={this.props.resetOutput}
                                calculationResult={calculationResult}
                            />
                        </div>

                        <div className="col-sm-6">
                            <Output
                                id="output"
                                calculationResult={
                                    hasResult ? calculationResult : {}
                                }
                                conversion={
                                    hasResult
                                        ? calculationResult.conversions[0]
                                        : {}
                                }
                                quantities={
                                    hasResult
                                        ? calculationResult.conversions[0]
                                              .quantities
                                        : []
                                }
                                conversionStandard={conversionStandard}
                                material={material}
                                layout={layout}
                                handleBack={this.handleBack}
                                handleNewCalculation={this.handleNewCalculation}
                                handleClearScroll={this.scrollToElement}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    Calculation
)
