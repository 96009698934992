import React, { Component } from 'react'
import ApplicationRoutes from './ApplicationRoutes'
import { routes } from '../constants/routeStrings'
import { Link } from 'react-router-dom'

import { smoothScrollTop } from '../utils/utils'

class Home extends Component {
    componentDidMount = () => {
        smoothScrollTop()
    }

    render() {
        return (
            <div className="wrapper">
                <div>
                    <ApplicationRoutes />
                </div>

                <div className="flow-navigation">
                    <ul className="navigation-top text-center">
                        <li className="navigation-item">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/scu-logo--qcm.svg'
                                }
                                alt="logo"
                                className="scu-logo-nav mt-2"
                            />
                        </li>
                        <li className="navigation-item">
                            <Link to={routes.landing}>
                                <span className="icon-il--home icon-l"></span>
                                <span className="nav-button-label"> Home</span>
                            </Link>
                        </li>
                        <li className="navigation-item">
                            <Link to={routes.calculation}>
                                <span className="icon-il--calculator icon-l"></span>
                                <span className="nav-button-label"> QCM</span>
                            </Link>
                        </li>
                        <li className="navigation-item">
                            <Link to={routes.settings}>
                                <span className="icon-il--settings icon-l"></span>
                                <span className="nav-button-label">
                                    {' '}
                                    Settings
                                </span>
                            </Link>
                        </li>
                    </ul>

                    <ul className="navigation-bottom text-center">
                        <li className="navigation-item nav-item-bottom">
                            <button onClick={this.props.logout} className="">
                                <span className="icon-il--log-out icon-l"></span>
                                <span className="nav-button-label">
                                    {' '}
                                    Logout
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Home
