import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { routes } from './constants/routeStrings'

import { logout, goingToLogout } from './actions/authentication/loginActions'

//Components
import Login from './components/authentication/Login'
import Home from './components/Home'

import smoothscroll from 'smoothscroll-polyfill'

const mapStateToProps = (state) => {
    return {
        user: state.login.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        goingToLogout: () => dispatch(goingToLogout())
    }
}

class App extends Component {
    constructor(props) {
        super(props)

        smoothscroll.polyfill()
    }

    handleAuthentication = (username, password) => {
        this.props.login(username, password)
    }

    handleLogoutButton = () => {
        this.props.goingToLogout()

        setTimeout(() => {
            this.props.logout()
        }, 100)
    }

    render() {
        const { user } = this.props

        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path={routes.login}
                        render={() =>
                            !user ? <Login /> : <Redirect to={routes.landing} />
                        }
                    />
                    <Route
                        render={() =>
                            user ? (
                                <Home logout={this.handleLogoutButton} />
                            ) : (
                                <Redirect to={routes.login} />
                            )
                        }
                    />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
