import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { strings } from '../../constants/strings/mainStrings'

import Select, { components } from 'react-select'

//icon
import { starFull } from 'react-icons-kit/icomoon/starFull'
import { Icon } from 'react-icons-kit'

//tooltip
import { Tooltip } from 'bootstrap'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

//Calculation Actions
import {
    calculate,
    saveSessionInput,
    clearSessionInput,
    clearCalculationError,
    setFavourits
} from '../../actions/calculation/calculationActions'

import {
    isNumber,
    returnInputValue,
    updateLocalStorageIfNeeded
} from '../../utils/utils'

import conversions from './conversions.json'

const mapStateToProps = (state) => {
    return {
        goingToLogout: state.login.goingToLogout,
        calculating: state.mainCalculation.calculating,
        errors: state.mainCalculation.errors,
        showError: state.mainCalculation.showError,
        errorMessage: state.mainCalculation.errorMessage,
        inputObject: state.mainCalculation.inputObject,
        favourits: state.mainCalculation.favourits
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        calculate: (
            inputFields,
            outputFields,
            conversionStandard,
            material,
            layout,
            reference
        ) =>
            dispatch(
                calculate(
                    inputFields,
                    outputFields,
                    conversionStandard,
                    material,
                    layout,
                    reference
                )
            ),
        saveSessionInput: (inputObject) =>
            dispatch(saveSessionInput(inputObject)),
        clearSessionInput: () => dispatch(clearSessionInput()),
        clearCalculationError: () => dispatch(clearCalculationError()),
        setFavourits: (items) => dispatch(setFavourits(items))
    }
}

class InputV2 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputFields: {},
            conversionStandards: [],
            conversionStandard: null,
            conversionStandardDescription: null,
            material: null,
            materialDescription: null,
            materials: [],
            layouts: [],
            layout: null,
            reference: '',
            inputs: [],
            tooltipDescription: '',
            // Registers input validation errors
            inputErrors: {},

            // Current Selected TextField value
            currentField: null,

            // Tells us if there are no validation erros
            isValid: true,
            showInformation: false
        }

        this.tooltipRef = React.createRef()
    }

    // Fill state with initial values
    componentDidMount = () => {
        const result = this.init()
        var initialValues = this.getInitialValues()
        var reference = ''

        if (
            this.props.inputObject !== undefined &&
            Object.keys(this.props.inputObject).length > 0
        ) {
            reference = this.props.inputObject.reference
        }

        this.setState({
            inputFields: result,
            conversionStandards: this.getConversionStandards(),
            materials: this.getInitialMaterials(
                initialValues.conversionStandard
            ),
            layout: initialValues.layout,
            layouts: this.getInitialLayouts(
                initialValues.conversionStandard,
                initialValues.material
            ),
            conversionStandard: initialValues.conversionStandard,
            conversionStandardDescription:
                initialValues.conversionStandardDescription,
            material: initialValues.material,
            materialDescription: initialValues.materialDescription,
            reference: reference
        })

        this.handleInputFields(
            initialValues.conversionStandard,
            initialValues.material,
            initialValues.layout
        )

        this.props.handleConversionAndMaterialChanged(
            initialValues.conversionStandard,
            initialValues.material,
            initialValues.layout
        )

        // Check Conversions
        updateLocalStorageIfNeeded(conversions)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.change !== prevProps.change) {
            this.createInputFieldsObject(
                this.state.conversionStandard,
                this.state.material,
                this.state.layout
            )
            this.setState({ reference: '' })
        }

        if (
            this.state.conversionStandard !== prevState.conversionStandard ||
            this.state.material !== prevState.material
        ) {
            var tooltipDesc = `${this.state.conversionStandardDescription} ${this.state.material} = ${this.state.materialDescription}`

            new Tooltip(this.tooltipRef.current, {
                title: tooltipDesc,
                placement: 'bottom',
                trigger: 'hover',
                customClass: 'qcm-tooltip'
            })
        }
    }

    componentWillUnmount = () => {
        if (!this.props.goingToLogout) {
            this.props.saveSessionInput({
                inputFields: this.state.inputFields,
                conversionStandard: this.state.conversionStandard,
                material: this.state.material,
                layout: this.state.layout,
                reference: this.state.reference
            })
        } else {
            this.props.clearSessionInput()
        }
    }

    // Get Initial Input fields object
    init = () => {
        var object = {}

        var conversion = null
        var material = null
        var layout = null
        if (
            this.props.inputObject !== undefined &&
            Object.keys(this.props.inputObject).length > 0
        ) {
            conversion = this.props.inputObject.conversionStandard
            material = this.props.inputObject.material
            layout = this.props.inputObject.layout
        }

        if (
            conversion !== null &&
            material !== null &&
            layout !== null &&
            layout !== undefined
        ) {
            var foundConversion = conversions.find(
                (element) => element.conversion === conversion
            )

            var foundMaterial = foundConversion.materials.find(
                (element) => element.material === material
            )

            var foundLayoutIndex = foundMaterial.layouts.findIndex(
                (element) => element.layout === layout
            )
            var foundLayout = foundMaterial.layouts[foundLayoutIndex]

            foundLayout.input_fields.forEach((element) => {
                object[element.code] = this.getStoredValue(element.code)
            })
        } else {
            conversions[0].materials[0].layouts[0].input_fields.forEach(
                (element) => {
                    if (element.allowed_values === undefined) {
                        object[element.code] = null
                    } else {
                        object[element.code] =
                            element.allowed_values[0].toString()
                    }
                }
            )
        }

        return object
    }

    getStoredValue = (code) => {
        return this.props.inputObject.inputFields[code]
    }

    // Get Conversion Standards
    getConversionStandards = () => {
        var conversionsArray = []

        conversions.forEach((element) => {
            var materials = []
            element.materials.forEach((item) => {
                materials.push(item.material)
            })

            conversionsArray.push({
                conversion: element.conversion,
                materials: materials
            })
        })

        return conversionsArray
    }

    // Get initial conversion standard and material (first of the array)
    getInitialValues = () => {
        var conversion = null
        var material = null
        var layout = null

        if (
            this.props.inputObject !== undefined &&
            Object.keys(this.props.inputObject).length > 0
        ) {
            conversion = this.props.inputObject.conversionStandard
            material = this.props.inputObject.material
            layout = this.props.inputObject.layout
        }

        if (conversion !== null && material !== null && layout !== null) {
            var foundConversion = conversions.find(
                (element) => element.conversion === conversion
            )
            var foundConversionIndex = conversions.findIndex(
                (element) => element.conversion === conversion
            )

            var foundMaterialIndex = foundConversion.materials.findIndex(
                (element) => element.material === material
            )
            var foundMaterial = foundConversion.materials.find(
                (element) => element.material === material
            )

            var foundLayoutIndex = foundMaterial.layouts.findIndex(
                (element) => element.layout === layout
            )

            return {
                conversionStandard:
                    conversions[foundConversionIndex].conversion,
                conversionStandardDescription:
                    conversions[foundConversionIndex].conversionDescription,
                material:
                    conversions[foundConversionIndex].materials[
                        foundMaterialIndex
                    ].material,
                materialDescription:
                    conversions[foundConversionIndex].materials[
                        foundMaterialIndex
                    ].materialDescription,
                layout: conversions[foundConversionIndex].materials[
                    foundMaterialIndex
                ].layouts[foundLayoutIndex].layout
            }
        } else {
            let defaultConversionIndex = conversions.findIndex((item) =>
                this.searchInFavourits(item.conversion, undefined, undefined)
            )
            defaultConversionIndex =
                defaultConversionIndex >= 0 ? defaultConversionIndex : 0

            let conversionStandard =
                conversions[defaultConversionIndex].conversion
            let defaultMaterialIndex =  conversions[defaultConversionIndex].materials.findIndex((item)=>
            this.searchInFavourits( conversionStandard,item.material,undefined))
            defaultMaterialIndex = defaultMaterialIndex >= 0 ? defaultMaterialIndex : 0

             let material =  conversions[defaultConversionIndex].materials[defaultMaterialIndex].material
             let defaultLayoutIndex = conversions[defaultConversionIndex].materials[defaultMaterialIndex].layouts.findIndex((item)=>
             this.searchInFavourits( conversionStandard,material,item.layout))
             defaultLayoutIndex = defaultLayoutIndex >= 0 ? defaultLayoutIndex : 0

            
            return {
                conversionStandard:
                    conversions[defaultConversionIndex].conversion,
                conversionStandardDescription:
                    conversions[defaultConversionIndex].conversionDescription,
                material:
                    conversions[defaultConversionIndex].materials[defaultMaterialIndex].material,
                layout: conversions[defaultConversionIndex].materials[defaultMaterialIndex]
                    .layouts[defaultLayoutIndex].layout,
                materialDescription:
                    conversions[defaultConversionIndex].materials[defaultMaterialIndex]
                        .materialDescription
            }
        }
    }

    // Get initial materials (materials from the first conversion standard)
    getInitialMaterials = (conversionStandard) => {
        var materials = []

        var foundConversionIndex = conversions.findIndex(
            (element) => element.conversion === conversionStandard
        )

        conversions[foundConversionIndex].materials.forEach((element) => {
            materials.push(element.material)
        })

        return materials
    }
    // Get initial layouts (layouts from the first conversion standard)
    getInitialLayouts = (conversionStandard, material) => {
        var layouts = []

        var foundConversionIndex = conversions.findIndex(
            (element) => element.conversion === conversionStandard
        )
        layouts = conversions[foundConversionIndex].materials.find(
            (element) => element.material === material
        ).layouts

        return layouts.map((item) => item.layout)
    }

    // Create Input Fields objects using the selected conversion standard,material and layout
    createInputFieldsObject = (conversionStandard, material, layout) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )
        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )
        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )

        var fields = foundLayout.input_fields

        var object = {}

        fields.forEach((element) => {
            if (element.allowed_values === undefined) {
                object[element.code] = null
            } else {
                object[element.code] = element.allowed_values[0].toString()
            }
        })

        this.setState({ inputFields: object })
    }

    // Handle the submit button action
    handleSubmitButton = () => {
        const { inputFields, conversionStandard, material, layout, reference } =
            this.state

        var outputFields = this.getOutputFields(
            conversionStandard,
            material,
            layout
        )
        this.props.calculate(
            inputFields,
            outputFields,
            conversionStandard,
            material,
            layout,
            reference
        )

        this.props.handleSubmitScroll('output-section')
    }

    // Function that changes state's variables when input is changed
    handleChangeField = (name) => (event) => {
        // Change input state
        this.setState({
            inputFields: {
                ...this.state.inputFields,
                [name]: event.target.value
            }
        })

        // Handle Validation
        this.handleValidation({
            ...this.state.inputFields,
            [name]: event.target.value
        })

        if (Object.keys(this.props.calculationResult).length !== 0) {
            this.props.resetOutput()
        }
    }

    // Check if we need to change other fields
    checkRelatedFieldsChange = (
        conversionStandard,
        material,
        layout,
        fieldName,
        value
    ) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )

        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )

        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )

        var changedField = foundLayout.input_fields.find(
            (element) => element.code === fieldName
        )

        if (this.state.currentField === this.state.inputFields[fieldName]) {
            return null
        }

        if (changedField.related_fields !== undefined) {
            try {
                var relatedFields = changedField.related_fields

                relatedFields.forEach((element) => {
                    var formula = element.function

                    formula = formula.replace('X', value)

                    // eslint-disable-next-line
                    var result = eval(formula)

                    var finalResult = returnInputValue(result)

                    if (finalResult !== this.state.inputFields[element.code]) {
                        this.setState({
                            inputFields: {
                                ...this.state.inputFields,
                                [element.code]: finalResult
                            }
                        })

                        // Check If number inputs are valid
                        this.handleValidation({
                            ...this.state.inputFields,
                            [element.code]: finalResult
                        })
                    }
                })
            } catch (error) {}
        }
    }

    // Handle when conversion standard is changed and find favourite's material and layout
    handleChangeConversion =
        () =>
        ({ conversion }) => {
            const { favourits } = this.props
            var returnedConversion = this.state.conversionStandards.find(
                (element) => element.conversion === conversion
            )
            var materials = returnedConversion.materials.map((item) => ({
                material: item
            }))
            let defaultMaterialIndex = materials.findIndex((item) =>
                favourits.find((favitem) => {
                    return (
                        favitem.conversion === conversion &&
                        favitem.material === item.material &&
                        !favitem.layout
                    )
                })
            )
            defaultMaterialIndex =
                defaultMaterialIndex >= 0 ? defaultMaterialIndex : 0
            var material = returnedConversion.materials[defaultMaterialIndex]
            const selectedConverion = conversions.find(
                (element) => element.conversion === conversion
            )
            const layouts =
                selectedConverion.materials[defaultMaterialIndex].layouts
            let defaultLayoutIndex = layouts.findIndex((item) =>
                favourits.find((favitem) => {
                    return (
                        favitem.conversion === conversion &&
                        favitem.material === material &&
                        favitem.layout === item.layout
                    )
                })
            )
            defaultLayoutIndex =
                defaultLayoutIndex >= 0 ? defaultLayoutIndex : 0
            var descriptions = this.getConversionAndMaterialDescriptions(
                conversion,
                material
            )
            var tooltipDescription = `${descriptions.conversionDescription} ${material} = ${descriptions.materialDescription}`
            this.setState({
                conversionStandard: conversion,
                conversionStandardDescription:
                    descriptions.conversionDescription,
                materials: returnedConversion.materials,
                material: material,
                layouts: layouts.map((item) => item.layout),
                layout: layouts[defaultLayoutIndex].layout,
                materialDescription: descriptions.materialDescription,
                tooltipDescription: tooltipDescription,
                reference: ''
            })
            this.handleInputFields(
                returnedConversion.conversion,
                material,
                layouts[defaultLayoutIndex].layout
            )

            //Create object
            this.createInputFieldsObject(
                conversion,
                material,
                layouts[defaultLayoutIndex].layout
            )
            this.props.handleConversionAndMaterialChanged(
                conversion,
                material,
                layouts[defaultLayoutIndex].layout
            )

            this.props.clearCalculationError()
        }

    // Handle when material is changed and find favourite's layout
    handleChangeMaterial =
        () =>
        ({ material }) => {
            const { favourits } = this.props
            const selectedConverion = conversions.find(
                (element) =>
                    element.conversion === this.state.conversionStandard
            )
            const selectedMaterial = selectedConverion.materials.find(
                (element) => element.material === material
            )
            const layouts = selectedMaterial.layouts
            let defaultLayoutIndex = layouts.findIndex((item) =>
                favourits.find((favitem) => {
                    return (
                        favitem.conversion === this.state.conversionStandard &&
                        favitem.material === material &&
                        favitem.layout === item.layout
                    )
                })
            )
            defaultLayoutIndex =
                defaultLayoutIndex >= 0 ? defaultLayoutIndex : 0
            const layout = layouts[defaultLayoutIndex].layout

            this.createInputFieldsObject(
                this.state.conversionStandard,
                material,
                layout
            )

            this.handleInputFields(
                this.state.conversionStandard,
                material,
                layout
            )

            var descriptions = this.getConversionAndMaterialDescriptions(
                this.state.conversionStandard,
                material
            )
            var tooltipDescription = `${descriptions.conversionDescription} ${material} = ${descriptions.materialDescription}`
            this.setState({
                material: material,
                materialDescription: descriptions.materialDescription,
                layout,
                layouts: layouts.map((item) => item.layout),
                tooltipDescription: tooltipDescription,
                reference: ''
            })

            this.props.handleConversionAndMaterialChanged(
                this.state.conversionStandard,
                material,
                layout
            )

            this.props.clearCalculationError()
        }

    handleChangeLayout =
        () =>
        ({ layout }) => {
            this.createInputFieldsObject(
                this.state.conversionStandard,
                this.state.material,
                layout
            )

            this.handleInputFields(
                this.state.conversionStandard,
                this.state.material,
                layout
            )

            this.setState({
                layout: layout
            })

            this.props.handleChanged('layout', layout)

            this.props.clearCalculationError()
        }

    getConversionAndMaterialDescriptions = (conversionStandard, material) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )
        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )

        return {
            conversionDescription: foundConversion.conversionDescription,
            materialDescription: foundMaterial.materialDescription
        }
    }

    // Update inputs array
    handleInputFields = (conversionStandard, material, layout) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )
        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )
        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )
        var fields = foundLayout.input_fields

        this.setState({ inputs: fields })
    }

    // Handle when reference is changed
    handleChangeReference = () => (event) => {
        this.setState({ reference: event.target.value })
    }
    getOutputFields = (conversionStandard, material, layout) => {
        var returnedConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )
        var returnedMaterial = returnedConversion.materials.find(
            (element) => element.material === material
        )
        var returnedLayout = returnedMaterial.layouts.find(
            (element) => element.layout === layout
        )
        return returnedLayout.output_fields
    }

    // Returns value to disable/enable submit button
    canSubmit = (inputFields) => {
        var canSubmit = true

        if (
            this.state.conversionStandard === null ||
            this.state.conversionStandard === '' ||
            this.state.material === null ||
            this.state.material === ''
        ) {
            return false
        }

        var foundConversion = conversions.find(
            (element) => element.conversion === this.state.conversionStandard
        )

        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === this.state.material
        )

        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === this.state.layout
        )

        if (foundLayout.requiredInputFieldSets !== undefined) {
            var requiredInputFieldSets = foundLayout.requiredInputFieldSets

            var finalBoolean = false

            // Foreach all combinations
            requiredInputFieldSets.forEach((element) => {
                var eachIteration = true

                // Foreach specific combination
                element.forEach((item) => {
                    if (
                        inputFields[item] === null ||
                        inputFields[item] === ''
                    ) {
                        eachIteration = false
                    }
                })

                if (eachIteration) {
                    finalBoolean = true
                }
            })
            canSubmit = finalBoolean
        }

        return canSubmit && this.state.isValid
    }

    // Check if field has min/max values
    hasMinOrMax = (conversionStandard, material, layout, fieldName, value) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )
        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )
        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )

        var inputField = foundLayout.input_fields.find(
            (element) => element.code === fieldName
        )
        if (
            inputField.min_value !== undefined ||
            inputField.max_value !== undefined
        ) {
            if (
                inputField.min_value !== undefined &&
                value < inputField.min_value
            ) {
                return 'Has to be >= ' + inputField.min_value
            } else if (
                inputField.max_value !== undefined &&
                value > inputField.max_value
            ) {
                return 'Has to be <= ' + inputField.max_value
            }
        }

        return null
    }

    handleValidation = (inputFields) => {
        let errors = {}

        Object.keys(inputFields).forEach((key) => {
            if (
                inputFields[key] !== null &&
                inputFields[key] !== '' &&
                !isNumber(inputFields[key])
            ) {
                errors[key] = 'Has to be a number!'
            } else if (inputFields[key] !== null && inputFields[key] !== '') {
                var minOrMax = this.hasMinOrMax(
                    this.state.conversionStandard,
                    this.state.material,
                    this.state.layout,
                    key,
                    inputFields[key]
                )

                if (minOrMax !== null) {
                    errors[key] = minOrMax
                }
            }
        })

        // If object is empty it means that there aren't any validation errors
        var valid = Object.keys(errors).length === 0

        this.setState({ inputErrors: errors, isValid: valid })
    }

    writeErrors = (inputErrors, errors, fieldName) => {
        return (
            <>
                <span style={{ color: 'red' }}>
                    <small>{inputErrors[fieldName]}</small>
                </span>
                <span style={{ color: 'red' }}>
                    <small>
                        {errors[fieldName] !== undefined
                            ? errors[fieldName][0]
                            : ''}
                    </small>
                </span>
            </>
        )
    }

    showOrHideInformation = () => {
        this.setState({ showInformation: !this.state.showInformation })
    }

    setSelectedFieldValue = (fieldName) => {
        this.setState({ currentField: this.state.inputFields[fieldName] })
    }

    //search the items in favourit's list in localhost
    searchInFavourits(conversion, material, layout) {
        const { favourits } = this.props

        return favourits.find((favitem) => {
            return (
                favitem.conversion === conversion &&
                favitem.material === material &&
                favitem.layout === layout
            )
        })
    }

    render() {
        const { calculating, errors, showError, errorMessage } = this.props
        const {
            inputFields,
            inputErrors,
            conversionStandards,
            conversionStandard,
            materials,
            material,
            layouts,
            layout,
            reference,
            inputs
        } = this.state

        var { favourits } = this.props
        //show the converion, material and layout in dropdown with favoutite's icons
        const Option = (props) => {
            const {
                conversion: optionConversion,
                material: optionMaterial,
                layout: optionLayout
            } = props.data

            let isFav = false
            if (optionConversion) {
                if (
                    this.searchInFavourits(
                        optionConversion,
                        undefined,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionMaterial) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        optionMaterial,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionLayout) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        material,
                        optionLayout
                    )
                )
                    isFav = true
            }
            return (
                <components.Option {...props} className="select-favourit">
                    {props.label}
                    <span className={'favourit'}>
                        <Tippy
                            theme="light-border"
                            placement="right"
                            content="favorite"
                        >
                            <div style={{ color: '#2A9D8F' }}>
                                {isFav ? <Icon icon={starFull} /> : ''}
                            </div>
                        </Tippy>
                    </span>
                </components.Option>
            )
        }
        //show the first option in dropdown
        const SingleValue = ({ children, ...props }) => {
            const {
                conversion: optionConversion,
                material: optionMaterial,
                layout: optionLayout
            } = props.data
            let isFav = false
            if (optionConversion) {
                if (
                    this.searchInFavourits(
                        optionConversion,
                        undefined,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionMaterial) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        optionMaterial,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionLayout) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        material,
                        optionLayout
                    )
                )
                    isFav = true
            }
            return (
                <components.SingleValue {...props}>
                    {children}
                    {isFav && (
                        <Tippy
                            theme="light-border"
                            placement="right"
                            content="favorite"
                        >
                            <div>
                                <Icon
                                    style={{
                                        color: '#2A9D8F',
                                        marginLeft: '10px'
                                    }}
                                    icon={starFull}
                                />
                            </div>
                        </Tippy>
                    )}
                </components.SingleValue>
            )
        }

        return (
            <div className="default-input-layout" id="input-section">
                <h2>
                    <span className="icon-il--download"></span> {strings.input}
                </h2>

                <div className="row">
                    <div className="col-9 col-md-10">
                        <div className="default-input-layout">
                            {/* CONVERSION STANDARD DROPDOWN */}
                            <label>
                                <b>{strings.conversionStandard}</b>
                            </label>
                            {
                                //dropdown code before favourite's itemm
                                /* <select
                                name="conversionStandards"
                                id="conversionStandards"
                                className="form-select"
                                disabled={calculating}
                                onChange={this.handleChangeConversion()}
                                value={conversionStandard || ''}
                            >
                                {conversionStandards.map((conversion) => (
                                    <option
                                        key={conversion.conversion}
                                        value={conversion.conversion}
                                    >
                                        {conversion.conversion}
                                    </option>
                                ))}
                            </select> */
                            }

                            {/* customized dropdown with select component */}
                            <Select
                                value={{ conversion: conversionStandard }}
                                options={conversionStandards}
                                onChange={this.handleChangeConversion()}
                                getOptionLabel={(item) => item.conversion}
                                getOptionValue={(item) => item.conversion}
                                components={{ Option, SingleValue }}
                                styles={{
                                    singleValue: (base) => ({
                                        ...base,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }),
                                    indicatorSeparator: (base) => ({
                                        ...base,
                                        display: 'none'
                                    }),
                                    control: (base, state) => {
                                        if (state.isFocused)
                                            return {
                                                ...base,
                                                borderColor: '#86b7fe',
                                                outline: 0,
                                                boxShadow:
                                                    '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                                            }
                                        else return base
                                    },
                                    input: (base) => ({
                                        ...base,
                                        height: '2.5rem'
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-3 col-md-2">
                        <button
                            onClick={this.showOrHideInformation}
                            ref={this.tooltipRef}
                            className="btn btn-outline-primary btn-info w-100"
                        >
                            <span className="icon-il--information icon-l"></span>
                        </button>
                    </div>

                    <div className="default-input-layout">
                        {/* MATERIAL DROPDOWN */}
                        <label>
                            <b>{strings.productGroup}</b>
                        </label>

                        <Select
                            value={{ material: material }}
                            options={materials.map((item) => ({
                                material: item
                            }))}
                            onChange={this.handleChangeMaterial()}
                            getOptionLabel={(item) => item.material}
                            getOptionValue={(item) => item.material}
                            components={{ Option, SingleValue }}
                            styles={{
                                singleValue: (base) => ({
                                    ...base,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: 'none'
                                }),
                                control: (base, state) => {
                                    if (state.isFocused)
                                        return {
                                            ...base,
                                            borderColor: '#86b7fe',
                                            outline: 0,
                                            boxShadow:
                                                '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                                        }
                                    else return base
                                },
                                input: (base) => ({
                                    ...base,
                                    height: '2.5rem'
                                })
                            }}
                        />
                    </div>

                    <div className="default-input-layout">
                        {/* LAYOUT DROPDOWN */}
                        <label>
                            <b>{strings.Layout}</b>
                        </label>
                        <Select
                            value={{ layout: layout }}
                            options={layouts.map((item) => ({
                                layout: item
                            }))}
                            onChange={this.handleChangeLayout()}
                            getOptionLabel={(item) => item.layout}
                            getOptionValue={(item) => item.layout}
                            components={{ Option, SingleValue }}
                            styles={{
                                singleValue: (base) => ({
                                    ...base,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: 'none'
                                }),
                                control: (base, state) => {
                                    if (state.isFocused)
                                        return {
                                            ...base,
                                            borderColor: '#86b7fe',
                                            outline: 0,
                                            boxShadow:
                                                '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                                        }
                                    else return base
                                },
                                input: (base) => ({
                                    ...base,
                                    height: '2.5rem'
                                })
                            }}
                        />
                    </div>
                </div>

                <br />

                <div className="default-input-layout">
                    {/* REFERENCE INPUT */}
                    <label>
                        <b>{strings.yourReference}</b>
                    </label>
                    <input
                        type="text"
                        id="reference"
                        disabled={calculating}
                        onChange={this.handleChangeReference()}
                        value={reference || ''}
                    />
                </div>

                {inputs.map((field) => (
                    <div className="default-input-layout" key={field.code}>
                        <label key={field.description}>
                            <b>{field.description}</b>
                        </label>
                        {field.allowed_values === undefined ? (
                            <input
                                type="text"
                                key={field.code}
                                disabled={this.props.calculating}
                                onChange={this.handleChangeField(field.code)}
                                onBlur={() =>
                                    this.checkRelatedFieldsChange(
                                        this.state.conversionStandard,
                                        this.state.material,
                                        this.state.layout,
                                        field.code,
                                        inputFields[field.code]
                                    )
                                }
                                value={inputFields[field.code] || ''}
                                onFocus={() =>
                                    this.setSelectedFieldValue(field.code)
                                }
                            />
                        ) : (
                            <select
                                id={field.code}
                                className="form-select"
                                disabled={calculating}
                                onChange={this.handleChangeField(field.code)}
                                onBlur={() =>
                                    this.checkRelatedFieldsChange(
                                        this.state.conversionStandard,
                                        this.state.material,
                                        this.state.layout,
                                        field.code,
                                        inputFields[field.code]
                                    )
                                }
                                value={inputFields[field.code] || ''}
                                onFocus={() =>
                                    this.setSelectedFieldValue(field.code)
                                }
                            >
                                {field.allowed_values.map((allowed_value) => (
                                    <option
                                        key={allowed_value}
                                        value={allowed_value}
                                    >
                                        {allowed_value}
                                    </option>
                                ))}
                            </select>
                        )}
                        {this.writeErrors(inputErrors, errors, field.code)}
                    </div>
                ))}

                {showError && (
                    <div className="alert alert-danger mt-3" role="alert">
                        <span className="icon-il--warning"></span>
                        <label>{errorMessage}</label>
                    </div>
                )}

                <div className="default-input-layout scu-wait-over-button">
                    {/* SUBMIT BUTTON */}
                    <button
                        onClick={this.handleSubmitButton}
                        disabled={calculating || !this.canSubmit(inputFields)}
                        className="btn btn-primary btn-success btn-calculate btn-flow-floating"
                    >
                        <span className="icon-il--calculator icon-l icon"></span>{' '}
                        {strings.calculateConversion}
                    </button>

                    {/* Show "Loading..." when waiting for API response */}
                    {calculating && (
                        <div className="scu-wait-container w-100">
                            <div className="scu-calculate-wait">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/assets/img/hourglass.svg'
                                    }
                                    alt="logo"
                                    className="scu-hourglass-calculate rotate"
                                />
                                <h6>Calculating...</h6>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputV2)
