import { restApi } from '../../interceptor'
// import { config } from '../../constants/config'
import { generateCalculationObjects, getHeader } from '../../utils/utils'

export function calculate(
    inputFields,
    outputFields,
    conversionStandard,
    material,
    layout,
    reference
) {
    return (dispatch) => {
        dispatch({ type: 'CALCULATE_RESULT_PENDING' })

        let returnedArray = generateCalculationObjects(
            inputFields,
            outputFields,
            conversionStandard,
            material,
            layout
        )

        restApi({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/convert`,
            headers: getHeader(),
            data: [
                {
                    language: 'en-US',
                    clientContextId: '{f40ae6ec-8321-11eb-b04a-b7922416028c}',
                    conversionStandard: conversionStandard,
                    materialClass: material,
                    layoutClass: layout,
                    clientRef: reference,
                    conversions: [
                        {
                            clientRef: reference,
                            quantities: returnedArray
                        }
                    ]
                }
            ]
        })
            .then((response) => {
                dispatch({
                    type: 'CALCULATE_RESULT_FULFILLED',
                    payload: {
                        calculationResult: response.data[0]
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'CALCULATE_RESULT_REJECTED',
                    payload: {
                        errors: err.response ? err.response.data.errors : {}
                    }
                })
            })
    }
}

export function saveSessionInput(inputObject) {
    return (dispatch) => {
        dispatch({
            type: 'SAVE_SESSION_INPUT',
            payload: { inputObject: inputObject }
        })
    }
}

export function clearCalculationError() {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_CALULATION_ERROR' })
    }
}

export function clearSessionInput() {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_SESSION_INPUT' })
    }
}

export function resetOutput() {
    return (dispatch) => {
        dispatch({ type: 'RESET_CALCULATION_OUTPUT' })
    }
}

export function setFavourits(items) {
    return (dispatch) => {
        dispatch({ type: 'SET_FAVOURITS', payload: items })
    }
}
