export const en = {
    input: 'Input',
    output: 'Output',
    conversionStandard: 'Conversion Standard',
    productGroup: 'Product Group',
    Layout: 'Layout',
    yourReference: 'Your Reference',
    usBarrels: 'US Barrels',
    usGallons: 'US Gallons',
    usBarrelsF: 'US Barrels @ 60ºF',
    usGallonsF: 'US Gallons @ 60ºF',
    productTemperature: 'Product Temperature (ºF)',
    api: 'ºAPI',
    apiF: 'ºAPI @ 60ºF',
    apiTemperature: 'ºAPI Temperature (ºF)',
    calculateConversion: 'Calculate Conversion',
    appliedProcedure: 'Applied Procedure',
    timeWhenCalculated: 'Time When Calculated',
    saveCalculation: 'Save Calculation',
    newCalculation: 'New Calculation',
    printCalculation: 'Print Calculation',
    downloadCalculation: 'Download Calculation'
}

export const de = {
    input: 'Input',
    output: 'Output',
    conversionStandard: 'Conversion Standard',
    productGroup: 'Product Group',
    Layout: 'Layout',
    yourReference: 'Your Reference',
    usBarrels: 'US Barrels',
    usGallons: 'US Gallons',
    usBarrelsF: 'US Barrels @ 60ºF',
    usGallonsF: 'US Gallons @ 60ºF',
    productTemperature: 'Product Temperature (ºF)',
    api: 'ºAPI',
    apiF: 'ºAPI @ 60ºF',
    apiTemperature: 'ºAPI Temperature (ºF)',
    calculateConversion: 'Calculate Conversion',
    appliedProcedure: 'Applied Procedure',
    timeWhenCalculated: 'Time When Calculated',
    saveCalculation: 'Save Calculation',
    newCalculation: 'New Calculation',
    printCalculation: 'Print Calculation',
    downloadCalculation: 'Download Calculation'
}
