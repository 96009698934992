import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

//Route strings import
import { routes } from '../constants/routeStrings'

//Components
import Calculation from './calculate/Calculation'
import LandingPage from './LandingPage'
import Settings from './settings/Settings'

class ApplicationRoutes extends Component {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={routes.calculation}
                    render={() => <Calculation />}
                />
                <Route
                    exact
                    path={routes.settings}
                    render={() => <Settings />}
                />
                <Route
                    exact
                    path={routes.landing}
                    render={() => <LandingPage />}
                />
            </Switch>
        )
    }
}

export default ApplicationRoutes
