import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Select, { components } from 'react-select'

// Strings
import { strings } from '../../constants/strings/mainStrings'

import { getUserSettings, getUsernameHash } from '../../utils/utils'
import conversions from '../calculate/conversions.json'
import { smoothScrollTop } from '../../utils/utils'

//Css File
import './Settings.scss'

import { setFavourits } from '../../actions/calculation/calculationActions'
//import { left } from '@popperjs/core'

//tooltip
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

//icon
import { Icon } from 'react-icons-kit'
import { starEmpty } from 'react-icons-kit/icomoon/starEmpty'
import { starFull } from 'react-icons-kit/icomoon/starFull'

// initial values from the calculate and add favourits
const mapStateToProps = (state) => {
    return {
        inputObject: state.mainCalculation.inputObject,
        favourits: state.mainCalculation.favourits
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFavourits: (items) => dispatch(setFavourits(items))
    }
}

class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userSettings: [],
            settingsFields: {},
            conversionStandards: [],
            materials: [],
            layouts: [],
            conversionStandard: null,
            material: null,
            layout: null,

            fields: []
        }
    }

    // Fill state with initial values
    componentDidMount = () => {
        var userSettingsArray = getUserSettings()

        setTimeout(() => {
            var initialValues = this.getInitialValues()

            const initalOutputFields = this.getInitialOutputFields(
                initialValues.conversionStandard,
                initialValues.material,
                initialValues.layout
            )
            const result = this.init(
                initialValues.conversionStandard,
                initialValues.material,
                initialValues.layout,
                initalOutputFields,
                userSettingsArray
            )

            this.setState({
                userSettings: userSettingsArray,
                settingsFields: result,
                conversionStandards: this.getConversionStandards(),
                materials: this.getInitialMaterials(
                    initialValues.conversionStandard
                ),
                layouts: this.getInitialLayouts(
                    initialValues.conversionStandard,
                    initialValues.material
                ),
                conversionStandard: initialValues.conversionStandard,
                material: initialValues.material,
                layout: initialValues.layout,
                reference: ''
            })
            this.handleOutputFields(
                initialValues.conversionStandard,
                initialValues.material,
                initialValues.layout
            )

            this.handleChangesOnUserSettings(
                initialValues.conversionStandard,
                initialValues.material,
                initialValues.layout,
                result,
                userSettingsArray
            )
            smoothScrollTop()
        }, 50)
    }

    // Get Initial Output fields object
    init = (
        initialConversion,
        initialMaterial,
        initialLayout,
        outputField,
        userSettings
    ) => {
        var object = {}
        outputField.forEach((element) => {
            object[element.code] =
                this.returnValueByConversionAndMaterialAndLayoutAndFieldName(
                    userSettings,
                    initialConversion,
                    initialMaterial,
                    initialLayout,
                    element.code
                ) || null
        })

        return object
    }

    // Get initial conversion standard and material (first of the array)
    getInitialValues = () => {
        var conversion = null
        var material = null
        var layout = null

        if (
            this.props.inputObject !== undefined &&
            Object.keys(this.props.inputObject).length > 0
        ) {
            conversion = this.props.inputObject.conversionStandard
            material = this.props.inputObject.material
            layout = this.props.inputObject.layout
        }

        if (conversion !== null && material !== null && layout !== null) {
            var foundConversion = conversions.find(
                (element) => element.conversion === conversion
            )
            var foundConversionIndex = conversions.findIndex(
                (element) => element.conversion === conversion
            )

            var foundMaterialIndex = foundConversion.materials.findIndex(
                (element) => element.material === material
            )
            var foundMaterial = foundConversion.materials.find(
                (element) => element.material === material
            )

            var foundLayoutIndex = foundMaterial.layouts.findIndex(
                (element) => element.layout === layout
            )

            return {
                conversionStandard:
                    conversions[foundConversionIndex].conversion,
                material:
                    conversions[foundConversionIndex].materials[
                        foundMaterialIndex
                    ].material,
                layout: conversions[foundConversionIndex].materials[
                    foundMaterialIndex
                ].layouts[foundLayoutIndex].layout
            }
        } else {
            return {
                conversionStandard: conversions[0].conversion,
                material: conversions[0].materials[0].material,
                layout: conversions[0].materials[0].layouts[0].layout
            }
        }
    }

    // Get Conversion Standards
    getConversionStandards = () => {
        var conversionsArray = []

        conversions.forEach((element) => {
            var materials = []
            element.materials.forEach((item) => {
                var layouts = []
                item.layouts.forEach((layoutItem) => {
                    layouts.push(layoutItem.layout)
                })
                materials.push({
                    material: item.material,
                    layouts: layouts
                })
            })
            conversionsArray.push({
                conversion: element.conversion,
                materials: materials
            })
        })
        return conversionsArray
    }

    // Get initial materials (materials from the first conversion standard)
    getInitialMaterials = (conversionStandard) => {
        var materials = []

        var foundConversionIndex = conversions.findIndex(
            (element) => element.conversion === conversionStandard
        )
        conversions[foundConversionIndex].materials.forEach((element) => {
            materials.push({
                material: element.material,
                layouts: []
            })
        })

        return materials
    }

    // Get initial layouts (layouts from the first conversion standard)
    getInitialLayouts = (conversionStandard, material) => {
        var layouts = []

        var foundConversionIndex = conversions.findIndex(
            (element) => element.conversion === conversionStandard
        )
        layouts = conversions[foundConversionIndex].materials.find(
            (element) => element.material === material
        ).layouts

        return layouts.map((item) => item.layout)
    }

    // Update settings array
    handleOutputFields = (conversionStandard, material, layout) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )

        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )

        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )

        var fields = foundLayout.output_fields

        this.setState({ fields: fields })
    }

    getInitialOutputFields = (conversionStandard, material, layout) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )

        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )

        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )

        var fields = foundLayout.output_fields

        return fields
    }

    // Create Input Fields objects using the selected conversion standard and material
    createInputFieldsObject = (conversionStandard, material, layout) => {
        var foundConversion = conversions.find(
            (element) => element.conversion === conversionStandard
        )
        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )
        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )
        var fields = foundLayout.output_fields

        var object = {}

        fields.forEach((element) => {
            object[element.code] =
                this.returnValueByConversionAndMaterialAndLayoutAndFieldName(
                    this.state.userSettings,
                    foundConversion.conversion,
                    foundMaterial.material,
                    foundLayout.layout,
                    element.code
                )
        })
        this.setState({ settingsFields: object })
        return object
    }

    handleChangeField = (name) => (event) => {
        // Change input state
        this.setState({
            settingsFields: {
                ...this.state.settingsFields,
                [name]: event.target.value
            }
        })

        this.handleChangesOnUserSettings(
            this.state.conversionStandard,
            this.state.material,
            this.state.layout,
            { ...this.state.settingsFields, [name]: event.target.value },
            this.state.userSettings
        )
    }

    handleSaveButton = () => {
        localStorage.setItem(
            getUsernameHash(),
            JSON.stringify(this.state.userSettings)
        )
        this.setState({ userSettings: getUserSettings() })
    }

    //check if items is in favourite list
    searchInFavourits(conversion, material, layout) {
        const { favourits } = this.props

        return favourits.find((favitem) => {
            return (
                favitem.conversion === conversion &&
                favitem.material === material &&
                favitem.layout === layout
            )
        })
    }

    // Handle when conversion standard is changed and show favourit's material and layout
    handleChangeConversion =
        () =>
        ({ conversion }) => {
            var returnedConversion = this.state.conversionStandards.find(
                (element) => element.conversion === conversion
            )
            let defaultMaterialIndex = returnedConversion.materials.findIndex(
                (item) =>
                    this.searchInFavourits(conversion, item.material, undefined)
            )
            defaultMaterialIndex =
                defaultMaterialIndex >= 0 ? defaultMaterialIndex : 0
            var material = returnedConversion.materials[defaultMaterialIndex]
            const selectedConverion = conversions.find(
                (element) => element.conversion === conversion
            )
            const layouts =
                selectedConverion.materials[defaultMaterialIndex].layouts
            let defaultLayoutIndex = layouts.findIndex((item) =>
                this.searchInFavourits(
                    conversion,
                    material.material,
                    item.layout
                )
            )
            defaultLayoutIndex =
                defaultLayoutIndex >= 0 ? defaultLayoutIndex : 0

            this.setState({
                conversionStandard: conversion,
                materials: returnedConversion.materials,
                material: material.material,
                layouts: layouts.map((item) => item.layout),
                layout: layouts[defaultLayoutIndex].layout
            })

            this.handleOutputFields(
                returnedConversion.conversion,
                material.material,
                layouts[defaultLayoutIndex].layout
            )

            //Create object
            var settingsFields = this.createInputFieldsObject(
                returnedConversion.conversion,
                material.material,
                layouts[defaultLayoutIndex].layout
            )

            this.handleChangesOnUserSettings(
                returnedConversion.conversion,
                material.material,
                layouts[defaultLayoutIndex].layout,
                settingsFields,
                this.state.userSettings
            )

            this.setState({ userSettings: getUserSettings() })
        }

    // Handle when material is changed and show favourite's layout
    handleChangeMaterial =
        () =>
        ({ material }) => {
            const { favourits } = this.props
            const selectedConverion = conversions.find(
                (element) =>
                    element.conversion === this.state.conversionStandard
            )
            const selectedMaterial = selectedConverion.materials.find(
                (element) => element.material === material
            )

            const layouts = selectedMaterial.layouts
            let defaultLayoutIndex = layouts.findIndex((item) =>
                this.searchInFavourits(
                    this.state.conversionStandard,
                    material,
                    item.layout
                )
            )
            defaultLayoutIndex =
                defaultLayoutIndex >= 0 ? defaultLayoutIndex : 0
            const layout = layouts[defaultLayoutIndex].layout

            var settingsFields = this.createInputFieldsObject(
                this.state.conversionStandard,
                material,
                layout
            )

            this.handleOutputFields(
                this.state.conversionStandard,
                material,
                layout
            )

            this.setState({
                material: material,
                layout: layout,
                layouts: layouts.map((item) => item.layout)
            })

            this.handleChangesOnUserSettings(
                this.state.conversionStandard,
                material,
                layout,
                settingsFields,
                this.state.userSettings
            )

            this.setState({ userSettings: getUserSettings() })
        }

    handleChangeLayout =
        () =>
        ({ layout }) => {
            this.handleOutputFields(
                this.state.conversionStandard,
                this.state.material,
                layout
            )

            this.createInputFieldsObject(
                this.state.conversionStandard,
                this.state.material,
                layout
            )

            this.setState({
                layout: layout
            })
        }

    handleChangesOnUserSettings = (
        conversionStandard,
        material,
        layout,
        settingsFields,
        userSettings
    ) => {
        var userSettingsArray = userSettings
        var foundConversion = userSettingsArray.find(
            (element) => element.conversion === conversionStandard
        )
        var foundConversionIndex = userSettingsArray.findIndex(
            (element) => element.conversion === conversionStandard
        )

        if (foundConversion === undefined) {
            userSettingsArray.push({
                conversion: conversionStandard,
                materials: []
            })

            foundConversion = userSettingsArray.find(
                (element) => element.conversion === conversionStandard
            )
            foundConversionIndex = userSettingsArray.findIndex(
                (element) => element.conversion === conversionStandard
            )
        }

        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )

        if (foundMaterial === undefined) {
            foundConversion.materials.push({
                material: material,
                layouts: [
                    {
                        layout: layout,
                        output_fields: settingsFields
                    }
                ]
            })
        } else {
            var foundMaterialIndex = foundConversion.materials.findIndex(
                (element) => element.material === material
            )

            var foundLayout = foundMaterial.layouts.find(
                (element) => element.layout === layout
            )

            if (foundLayout === undefined) {
                foundMaterial.layouts.push({
                    layout: layout,
                    output_fields: settingsFields
                })
            } else {
                var foundLayoutIndex = foundMaterial.layouts.findIndex(
                    (element) => element.layout === layout
                )
                userSettingsArray[foundConversionIndex].materials[
                    foundMaterialIndex
                ].layouts[foundLayoutIndex].output_fields = settingsFields
            }
        }

        this.setState({ userSettings: userSettingsArray })
    }

    returnValueByConversionAndMaterialAndLayoutAndFieldName = (
        userSettings,
        conversionStandard,
        material,
        layout,
        fieldName
    ) => {
        var foundConversion = userSettings.find(
            (element) => element.conversion === conversionStandard
        )

        if (foundConversion === undefined) return null

        var foundMaterial = foundConversion.materials.find(
            (element) => element.material === material
        )

        if (foundMaterial === undefined) return null

        var foundLayout = foundMaterial.layouts.find(
            (element) => element.layout === layout
        )

        if (foundLayout === undefined) return null

        var value = foundLayout.output_fields[fieldName]

        return value === undefined ? null : value
    }

    //handle favourits and add converion or material or layout when as favourits selected
    handleFavItem(item) {
        const { favourits, setFavourits } = this.props
        const {
            conversion: optionConversion,
            material: optionMaterial,
            layout: optionLayout
        } = item
        const { conversionStandard, material } = this.state
        if (optionConversion) {
            if (this.searchInFavourits(optionConversion, undefined, undefined))
                setFavourits(
                    //deselect if the item is selected
                    favourits.filter((favitem) => {
                        return (
                            favitem.conversion !== optionConversion ||
                            favitem.material !== optionMaterial ||
                            favitem.layout !== optionMaterial
                        )
                    })
                )
            else {
                setFavourits([
                    ...favourits.filter(
                        (favItem) =>
                            !favItem.conversion ||
                            favItem.material ||
                            favItem.layout
                    ),
                    { conversion: optionConversion }
                ])
            }
        } else if (optionMaterial) {
            if (
                this.searchInFavourits(
                    conversionStandard,
                    optionMaterial,
                    undefined
                )
            )
                setFavourits(
                    //deselect if the item is selected
                    favourits.filter((favitem) => {
                        return (
                            (favitem.conversion !== optionConversion &&
                                favitem.material !== optionMaterial) ||
                            favitem.layout !== optionLayout
                        )
                    })
                )
            else
                setFavourits([
                    ...favourits.filter(
                        (favItem) =>
                            favItem.conversion !== conversionStandard ||
                            !favItem.material ||
                            favItem.layout
                    ),
                    { conversion: conversionStandard, material: optionMaterial }
                ])
        } else if (optionLayout) {
            if (
                this.searchInFavourits(
                    conversionStandard,
                    material,
                    optionLayout
                )
            )
                setFavourits(
                    //deselect if the item is selected
                    favourits.filter((favitem) => {
                        return favitem.layout !== optionLayout
                    })
                )
            else
                setFavourits([
                    ...favourits.filter(
                        (favItem) =>
                            favItem.conversion !== conversionStandard ||
                            favItem.material !== material ||
                            !favItem.layout
                    ),
                    {
                        conversion: conversionStandard,
                        material: material,
                        layout: optionLayout
                    }
                ])
        }
    }

    render() {
        const {
            settingsFields,
            conversionStandards,
            materials,
            layouts,
            conversionStandard,
            material,
            layout,
            fields
        } = this.state

        //show conversion,material and layout and selected items as favourites in dropdowns
        const Option = (props) => {
            const {
                conversion: optionConversion,
                material: optionMaterial,
                layout: optionLayout
            } = props.data
            let isFav = false
            if (optionConversion) {
                if (
                    this.searchInFavourits(
                        optionConversion,
                        undefined,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionMaterial) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        optionMaterial,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionLayout) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        material,
                        optionLayout
                    )
                )
                    isFav = true
            }

            return (
                <components.Option {...props} className="select-favourit">
                    {props.label}

                    <span className={'favourit'}>
                        <Tippy
                            theme="light-border"
                            placement="right"
                            content="favorite"
                        >
                            <div
                                style={{ color: '#2A9D8F', cursor: 'pointer' }}
                                onClick={() => this.handleFavItem(props.data)}
                            >
                                <Icon
                                    style={{
                                        visibility: isFav ? 'visible' : 'hidden'
                                    }}
                                    className={'favIcon'}
                                    icon={isFav ? starFull : starEmpty}
                                />
                            </div>
                        </Tippy>
                    </span>
                </components.Option>
            )
        }
        //show the first converion,material and layout items and favourite's item in dropdown
        const SingleValue = ({ children, ...props }) => {
            const {
                conversion: optionConversion,
                material: optionMaterial,
                layout: optionLayout
            } = props.data
            let isFav = false
            if (optionConversion) {
                if (
                    this.searchInFavourits(
                        optionConversion,
                        undefined,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionMaterial) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        optionMaterial,
                        undefined
                    )
                )
                    isFav = true
            } else if (optionLayout) {
                if (
                    this.searchInFavourits(
                        conversionStandard,
                        material,
                        optionLayout
                    )
                )
                    isFav = true
            }
            return (
                <components.SingleValue className="singleValue" {...props}>
                    {children}
                    {isFav && (
                        <Tippy
                            theme="light-border"
                            placement="right"
                            content="favorite"
                        >
                            <div>
                                <Icon
                                    style={{
                                        color: '#2A9D8F',
                                        marginLeft: '10px'
                                    }}
                                    icon={starFull}
                                />
                            </div>
                        </Tippy>
                    )}
                </components.SingleValue>
            )
        }

        return (
            <div className="container background">
                <h1 className="mb-3">
                    <span className="icon-il--settings"></span> Settings
                </h1>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="defaultSettingsLayout">
                            <div className="default-input-layout">
                                {/* CONVERSION STANDARD DROPDOWN */}
                                <label>
                                    <b>{strings.conversionStandard}</b>
                                </label>
                                <Select
                                    value={{ conversion: conversionStandard }}
                                    options={conversionStandards}
                                    onChange={this.handleChangeConversion()}
                                    getOptionLabel={(item) => item.conversion}
                                    getOptionValue={(item) => item.conversion}
                                    components={{ Option, SingleValue }}
                                    styles={{
                                        singleValue: (base) => ({
                                            ...base,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }),
                                        indicatorSeparator: (base) => ({
                                            ...base,
                                            display: 'none'
                                        }),
                                        control: (base, state) => {
                                            if (state.isFocused)
                                                return {
                                                    ...base,
                                                    borderColor: '#86b7fe',
                                                    outline: 0,
                                                    boxShadow:
                                                        '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                                                }
                                            else return base
                                        },
                                        input: (base) => ({
                                            ...base,
                                            height: '2.5rem'
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="defaultSettingsLayout">
                            <div className="default-input-layout">
                                {/* MATERIAL DROPDOWN */}
                                <label>
                                    <b>{strings.productGroup}</b>
                                </label>
                                {/* select dropdown */}
                                <Select
                                    value={{ material: material }}
                                    options={materials}
                                    onChange={this.handleChangeMaterial()}
                                    getOptionLabel={(item) => item.material}
                                    getOptionValue={(item) => item.material}
                                    components={{ Option, SingleValue }}
                                    styles={{
                                        singleValue: (base) => ({
                                            ...base,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }),
                                        indicatorSeparator: (base) => ({
                                            ...base,
                                            display: 'none'
                                        }),
                                        control: (base, state) => {
                                            if (state.isFocused)
                                                return {
                                                    ...base,
                                                    borderColor: '#86b7fe',
                                                    outline: 0,
                                                    boxShadow:
                                                        '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                                                }
                                            else return base
                                        },
                                        input: (base) => ({
                                            ...base,
                                            height: '2.5rem'
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="default-input-layout">
                            {/* LAYOUT DROPDOWN */}
                            <label>
                                <b>{strings.Layout}</b>
                            </label>
                            <Select
                                value={{ layout: layout }}
                                options={layouts.map((item) => ({
                                    layout: item
                                }))}
                                onChange={this.handleChangeLayout()}
                                getOptionLabel={(item) => item.layout}
                                getOptionValue={(item) => item.layout}
                                components={{ Option, SingleValue }}
                                styles={{
                                    singleValue: (base) => ({
                                        ...base,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }),
                                    indicatorSeparator: (base) => ({
                                        ...base,
                                        display: 'none'
                                    }),
                                    control: (base, state) => {
                                        if (state.isFocused)
                                            return {
                                                ...base,
                                                borderColor: '#86b7fe',
                                                outline: 0,
                                                boxShadow:
                                                    '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                                            }
                                        else return base
                                    },
                                    input: (base) => ({
                                        ...base,
                                        height: '2.5rem'
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="col-sm-6 mt-3 mt-sm-0">
                        {fields.map((field) => (
                            <Fragment key={field.code}>
                                <div
                                    className="defaultSettingsLayout"
                                    key={field.code}
                                >
                                    <div className="default-input-layout">
                                        <label key={field.description}>
                                            <b>
                                                {field.description} decimal
                                                places
                                            </b>
                                        </label>
                                        <input
                                            type="text"
                                            key={field.code}
                                            onChange={this.handleChangeField(
                                                field.code
                                            )}
                                            value={
                                                settingsFields[field.code] || ''
                                            }
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 mt-3 mt-sm-1">
                        <button
                            onClick={this.handleSaveButton}
                            className="btn btn-primary btn-success btn-calculate btn-flow-floating"
                        >
                            <span className="icon-il--save icon-l icon-l icon"></span>{' '}
                            Save Settings
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Settings)
